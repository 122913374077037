import { MoonIcon, SunIcon } from '@chakra-ui/icons';
import {
  Badge,
  Box,
  Button,
  HStack,
  IconButton,
  Image,
  Spacer,
  VStack,
  useColorMode,
} from '@chakra-ui/react';
import { motion } from 'framer-motion';
import { useEffect, useState } from 'react';
import { AttestorReport, AttestorReportDataMap } from './models';
import Status from './components/Status';
import { firePagerDutyTestAlert, clearPagerDutyTestAlert } from 'shared/pagerDuty';

// eslint-disable-next-line no-restricted-globals
const HOST = location.origin.replace(/^http/, 'ws');

function parseData(data: AttestorReport[]): AttestorReportDataMap {
  const dataMap: AttestorReportDataMap = {
    mainnet: data.filter((responseData: AttestorReport) => responseData.environment === 'mainnet'),
    testnet: data.filter((responseData: AttestorReport) => responseData.environment === 'testnet'),
    devnet: data.filter((responseData: AttestorReport) => responseData.environment === 'devnet'),
  };
  return dataMap;
}

export default function App() {
  const { colorMode, toggleColorMode } = useColorMode();

  const LogoSpin = motion(Image);

  const [serverConnected, setServerConnected] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const [wsData, setWsData] = useState<AttestorReportDataMap>({
    mainnet: [],
    testnet: [],
    devnet: [],
  });

  const [lastDataTime, setLastDataTime] = useState<Date | null>(null);

  let timeout = 2500; // Initial timeout duration
  function connectWebSocket() {
    const ws = new WebSocket(HOST);
    ws.onopen = () => {
      console.log('connected');
      setServerConnected(true);
      timeout = 2500; // reset timer
    };

    ws.onmessage = (evt) => {
      const message = JSON.parse(evt.data);
      console.log('ws message:', message);
      const dataMap = parseData(message);
      setWsData(dataMap);
      setLastDataTime(new Date());
      setIsLoading(false);
    };

    ws.onclose = () => {
      console.log(`disconnected, reconnecting in ${timeout / 1000}s...`);
      setServerConnected(false);
      setTimeout(
        () => {
          console.log('reconnecting...');
          connectWebSocket();
        },
        Math.min(10000, (timeout += timeout))
      );
    };

    // clean up on unmount
    return () => ws.close();
  }

  useEffect(() => {
    const fetchInitialData = async () => {
      const response = await fetch('/api/data');
      const data = await response.json();
      console.log('Data from datacache:', data);

      const dataMap = parseData(data);
      setWsData(dataMap);
      setLastDataTime(new Date());
    };
    setIsLoading(true);
    fetchInitialData();
  }, []);

  useEffect(() => {
    connectWebSocket();
  }, []);

  return (
    <VStack py="50px" w="100%" spacing="50px">
      <HStack w="50%">
        <LogoSpin
          width="5%"
          src="/dlcBTC-logo.svg"
          animate={{ rotate: 360 }}
          transition={{
            ease: 'linear',
            duration: 10,
            repeat: Infinity,
          }}
        />
        <Box fontStyle="italic" fontWeight="bold">
          dlcBTC Status Page
        </Box>
        <Box>
          <Badge ml={2} colorScheme={serverConnected ? 'green' : 'red'}>
            {serverConnected ? 'Connected' : 'Disconnected'}
          </Badge>
        </Box>
        <Box>
          <Badge ml={2} colorScheme={lastDataTime ? 'blue' : 'gray'}>
            Last Data: {lastDataTime?.toLocaleString()}
          </Badge>
        </Box>
        <Spacer />
        <IconButton
          rounded="full"
          aria-label="Toggle color mode"
          bgColor="transparent"
          onClick={toggleColorMode}
          icon={colorMode === 'light' ? <MoonIcon /> : <SunIcon />}
        />
      </HStack>
      <Status isLoading={isLoading} data={wsData} />
      <HStack w="20%">
        <Box fontStyle="italic" fontWeight="bold">
          Test Fire PagerDuty, wakey wakey!
        </Box>
        <Spacer />
        <Button
          colorScheme="red"
          onClick={() => {
            firePagerDutyTestAlert();
          }}
        >
          Fire!
        </Button>
        <Button
          colorScheme="red"
          onClick={() => {
            clearPagerDutyTestAlert();
          }}
        >
          Clear!
        </Button>
      </HStack>
    </VStack>
  );
}
