import { Spinner, VStack, useDisclosure } from '@chakra-ui/react';
import { useState } from 'react';
import { AttestorReportDataMap, AttestorReport, AttestorEnvironment } from '../models';
import DetailsModal from './DetailsModal';
import AttestorStatusTable from './AttestorStatusTable';

// function Status(props: { data: ResponseDataMap; isLoading: boolean }) {
function Status(props: { data: AttestorReportDataMap; isLoading: boolean }) {
  const [selectedEndpoint, setSelectedEndpoint] = useState<
    [AttestorReport, AttestorEnvironment] | null
  >(null);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { data, isLoading } = props;

  const openDetails = (item: AttestorReport, type: AttestorEnvironment) => {
    setSelectedEndpoint([item, type]);
    onOpen();
  };

  if (isLoading) {
    return <Spinner size="xl" />;
  }

  return (
    <VStack w="50%" spacing="50px">
      {Object.entries(data).map(([key, value]) => (
        // <GeneralStatusTable title={key as EndpointType} data={value} openModal={openDetails} />
        <AttestorStatusTable
          title={key as AttestorEnvironment}
          data={value}
          openModal={openDetails}
        />
      ))}
      {selectedEndpoint && (
        <DetailsModal
          type={selectedEndpoint[1]}
          selectedEndpoint={selectedEndpoint[0]}
          isOpen={isOpen}
          onClose={onClose}
        />
      )}
    </VStack>
  );
}

export default Status;
