import { Button, HStack, Heading, Table, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react';
import { AttestorEnvironment, AttestorStatusTableProps } from '../models';
import StatusBadge from './StatusBadge';
import BitcoinBadge from './BitcoinBadge';

function AttestorStatusTable({ title, data, openModal }: AttestorStatusTableProps) {
  const numEvents = data[0]?.numEvents;
  return (
    <>
      <HStack align="start" w="100%">
        <Heading as="h2" fontSize={{ base: '2xl', md: '3xl' }}>
          {title.charAt(0).toUpperCase() + title.slice(1)}
        </Heading>
      </HStack>
      <Table variant="simple">
        <Thead>
          <Tr>
            <Th>Operator</Th>
            <Th>Name</Th>
            <Th>Version</Th>
            <Th>Reach Bitcoin?</Th>
            <Th>EVM Healthy?</Th>
            <Th>VSR</Th>
            <Th>Status</Th>
            <Th>Actions</Th>
          </Tr>
        </Thead>
        <Tbody>
          {data?.map((item) => (
            <Tr key={item.name}>
              <Td w="25%">{item.operator}</Td>
              <Td w="45%">{item.name}</Td>
              <Td w="25%">{item.version}</Td>
              {/* <Td w="45%">{item.domain}</Td> */}
              <Td w="25%">
                <BitcoinBadge status={item.canTalkToBitcoin} />
              </Td>
              <Td w="25%">
                <BitcoinBadge status={item.evmHealth} />
              </Td>
              <Td w="25%">
                <BitcoinBadge status={item.VSR} />
              </Td>
              <Td w="25%">
                <a
                  href={`${item.domain}/app/health`}
                  target="_blank"
                  rel="noopener noreferrer"
                  aria-label="Health"
                >
                  <StatusBadge status={item.status} />
                </a>
              </Td>
              <Td w="15%">
                <Button onClick={() => openModal(item, title as AttestorEnvironment)}>
                  Details
                </Button>
              </Td>
            </Tr>
          ))}
          {/* {data?.map((item) => (
            <Tr key={item.endpoint.name}>
              <Td w="25%">{item.endpoint.name}</Td>
              <Td w="45%">{item.endpoint.url}</Td>
              <Td w="15%">
                <StatusBadge status={item.status} />
              </Td>
              <Td w="15%">
                <Button onClick={() => openModal(item, title as EndpointType)}>Details</Button>
              </Td>
            </Tr>
          ))} */}
        </Tbody>
      </Table>
    </>
  );
}

export default AttestorStatusTable;
